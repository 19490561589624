//import Keycloak from 'keycloak-js'
import store from '..'
import mainStore from '@/store/store';
import { CommunicationSettings } from "@/corvina-connect";

type GlobalWithKeycloak = typeof global & { Keycloak: any, $keycloak: any };

export default async (next?, roles?) => {

  let realm = "";
  
  try {
    realm = await CommunicationSettings.realm();
  } catch(err) {
    console.error("Error getting realm from CommunicationSettings: " + err);
    // write the error message in the window screen
    window.document.getElementById('global-fatal-error').style.display = "block";
    window.document.getElementById('global-fatal-error').innerHTML = `Error initializing. Please check the hostname is correct and retry`;
  }

  const gl = global as GlobalWithKeycloak;

  gl.$keycloak = new gl.Keycloak({
    url: CommunicationSettings.authApiUrl(),
    realm,
    clientId: 'corvina-web-portal',
  })

  let keycloakAuth = gl.$keycloak
  
  let authenticated = await keycloakAuth.init({ 
    onLoad: 'login-required', 
    responseMode: 'fragment', 
    checkLoginIframe: false,
    scope: sessionStorage.getItem('scope'),
  })
    .catch((error) => {
      console.error("KEYCLOAK INIT ERROR: " + error)
    })

  if (!authenticated) {
 //   window.location.reload() // Error message should be displayed in OrganizationComponent
  }

  await mainStore.dispatch('permission/setUserPermissions', {
    decoded: keycloakAuth.tokenParsed,
    access_token: keycloakAuth.token,
  });

  store.dispatch('authLogin', keycloakAuth)
  if (roles) {
    if (keycloakAuth.hasResourceRole(roles[0])) {
      next()
    }
    else {
      next({ name: 'Unauthorized' })
    }
  }
  else {
    //next()
  }
  setInterval(function () {
    console.log("UPDATE TOKEN...")
    keycloakAuth.updateToken(70)
      .then((refreshed) => {
        if (refreshed) {
          console.log('Token refreshed' + refreshed)

          mainStore.dispatch('permission/setUserPermissions', {
            decoded: keycloakAuth.tokenParsed,
            access_token: keycloakAuth.token,
          });
        }
        else {
          console.log('Token not refreshed, valid for ' + Math.round(keycloakAuth.tokenParsed.exp + keycloakAuth.timeSkew - new Date().getTime() / 1000) + ' seconds')
        }
      }).catch(function (error) {
        console.error('Failed to refresh token: ', error)
      })
  }, 60000)
}
